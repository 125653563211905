





















































































































































































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator'
import InfoCard from '@/general/components/common/InfoCard.vue'
import Popup from '@/general/components/common/Popup.vue'
import BaseDataTable from '@/general/components/general/BaseDataTable.vue'
import StatusUpdate from '@/modules/scan/components/StatusUpdate.vue'
import {
  GetPlanning_GetPlanning,
  GetPlanning_GetPlanning_consignments,
  GetPlanning_GetPlanning_consignments_actors,
  GetPlanning_GetPlanning_consignments_actors_pickup_person,
  GetPlanning_GetPlanning_stops,
  GetPlanning_GetPlanning_stops_activities,
  GetPlanning_GetPlanning_vehicles,
} from '@/generated/GetPlanning'
import {GetShipments_GetShipment_consignments} from '@/generated/GetShipments'
import {Action, Requirement, StatusState, StatusType} from '@/generated/globalTypes'
import {PlanningUtil} from '@/modules/planning/util/PlanningUtil'
import {PlanningState} from '@/stores/transport'
import PlanningService from "@/modules/planning/PlanningService";
import GPSMixin from "@/general/mixins/GPSMixin";
import StatusService from "@/modules/shipments/services/StatusService";
import { GetAbout_GetAbout } from '@/generated/GetAbout'

@Component({
  components: {
    BaseDataTable,
    Popup,
    InfoCard,
    StatusUpdate,
  },
})
export default class PlanningStops extends Mixins(PlanningUtil, PlanningService, GPSMixin, PlanningUtil, StatusService) {
  private LOCAL_STORAGE_PLANNING_FINISHED_CLICK_COUNT = 'planningFinishedClickCount'
  private LOCAL_STORAGE_TEMP_FINISH = 'planningTempFinish'

  stopId: number | null = null
  clickedStopForInfo: GetPlanning_GetPlanning_stops | null = null
  statusType = StatusType
  hide = false
  interval: NodeJS.Timeout | null = null

  planningFinishedClickCount: number = 0
  driverFinishedPlanningIndicator: boolean = false

  clickedDriverFinishedPlanning: boolean = false


  get stop(): GetPlanning_GetPlanning_stops | null {
    if (!this.stopId) return null
    return this.planning.stops.find(s => s.id == this.stopId) ?? null
  }

  get planning(): GetPlanning_GetPlanning {
    return this.$store.state.transport.planning
  }

  @Prop({ default: false }) mobile: boolean

  tempFinish: LocalStorageTempFinishStop[] = new Array<LocalStorageTempFinishStop>()
  private THREE_MINUTES = 180000

  get expand(): Array<Boolean> {
    return this.planningState.expand
  }

  activityInfo(props: any): string {
    const action = props.item.action
    const consignment = this.findConsignment(this.planning, props.item.consignment)!
    const crossdock = this.crossDockStatusTypes.includes(props.item.statusType)

    const customDisplay = this.$store.state.systemSettings.find((setting: GetAbout_GetAbout) => setting.key === 'ActivityInfo')?.value
    if (customDisplay) {
      return eval(customDisplay)
    } else {
      return this.getActivityIdentifierLine(crossdock, action, consignment!)
    }
  }

  setExpand(index: number, state: boolean) {
    this.planningState.expand[index] = state
    this.hide = true
    this.$nextTick(() => {
      this.hide = false
    })
  }
  get planningState(): PlanningState {
    if (!this.$store.state.transport.planningScroll[this.planning.id]) {
      const expand = this.planning.stops.map((stop, index) => !this.mobile && !this.isComplete(stop))
      this.$store.state.transport.planningScroll[this.planning.id] = { stop: undefined, expand }
    }
    return this.$store.state.transport.planningScroll[this.planning.id]
  }

  setTempFinish(tempfinish: LocalStorageTempFinishStop) {
    const finish = this.tempFinish.find(temp => temp.id === tempfinish.id)
    if (finish) {
      this.tempFinish = this.tempFinish.filter(temp => temp.id !== tempfinish.id)
      finish.activities.push(...tempfinish.activities)
    }
    this.tempFinish.push(finish ?? tempfinish)
    if (this.stop) {
      this.setExpand(this.planning.stops.indexOf(this.stop), false)
    }
    this.storeTempFinish()
  }

  private getStopForUpdate(stop: GetPlanning_GetPlanning_stops) {
    let filteredStop = Object.assign({}, stop)
    filteredStop.activities = stop.activities.filter(activity => !this.isActivityComplete(stop, activity))
    return filteredStop
  }

  containsOldenburger() {
    // CUS-611
    return window.location.origin.toLowerCase().includes("oldenburger")
  }

  created() {
    //@ts-ignore
    this.watchStop(this.$route.query.stop)

    if (localStorage.getItem(this.LOCAL_STORAGE_PLANNING_FINISHED_CLICK_COUNT)) {
      let clickCount = JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_PLANNING_FINISHED_CLICK_COUNT)!!) as LocalStorageClickCount
      if (clickCount.id === this.planning.id) {
        this.planningFinishedClickCount = clickCount.count
      }
    }

    this.checkTempFinish()

    if (!this.$store.state.nativeMode) {
      this.postGPS()
    }
  }

  @Watch('planning.name')
  updateMobileTitleFromUpdateNotification() {
    this.setMobileTitle()
  }

  @Watch('planning.updated')
  updateTempFinishFromUpdateNotification() {
    this.checkTempFinish()
  }

  checkTempFinish() {
    if (localStorage.getItem(this.LOCAL_STORAGE_TEMP_FINISH)) {
      let tempFinish = JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_TEMP_FINISH)!!) as LocalStorageTempFinish
      // Check tempFinish.stops for backwards compatability; previous versions didn't had .stops
      if (tempFinish.stops && tempFinish.id === this.planning.id && tempFinish.updated === new Date(this.planning.updated).getTime()) {
        this.tempFinish = tempFinish.stops.map(tempFinish => tempFinish)
      } else {
        localStorage.removeItem(this.LOCAL_STORAGE_TEMP_FINISH)
        this.tempFinish = []
      }
    }
  }

  setMobileTitle() {
    this.$store.state.mobileTitle = {
      title: `${this.planning.name ? ' ' + this.planning.name : ''}${this.planning.startDate ? ' ' + this.$options.filters!!.smallDateTime(this.planning.startDate) : ''}`,
      subtitle: `${this.planning.vehicles.map(vehicle => this.getVehicleString(vehicle)).filter(vehicleString => vehicleString).join(" / ")}`,
      extendedTitle: this.planning.instructions
    }
  }

  private getVehicleString(vehicle: GetPlanning_GetPlanning_vehicles): string {
    return [vehicle.description, vehicle.licensePlate].filter(attribute => attribute).join(" | ")
  }

  mounted() {
    this.setMobileTitle()
    this.scrollToCachedHeight()
  }

  destroyed() {
    this.$store.state.mobileTitle = undefined
    this.setPlanningFinishedCount()

    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  storeTempFinish() {
    if (this.tempFinish.length) {
      localStorage.setItem(this.LOCAL_STORAGE_TEMP_FINISH, JSON.stringify({ id: this.planning.id, stops: this.tempFinish, updated: new Date(this.planning.updated).getTime() } as LocalStorageTempFinish))
    }
  }

  setPlanningFinishedCount() {
    if (this.planningFinishedClickCount) {
      localStorage.setItem(this.LOCAL_STORAGE_PLANNING_FINISHED_CLICK_COUNT, JSON.stringify({ id: this.planning.id, count: this.planningFinishedClickCount } as LocalStorageClickCount))
    }
  }

  doFinishPlanning() {
    this.driverFinishedPlanningIndicator = true
    this.emitDriverFinishedPlanning(this.planning.id).then(result => {
      if (result) {
        this.$store.dispatch('snackbar/sent')
        this.planningFinishedClickCount = this.planningFinishedClickCount + 1
        this.setPlanningFinishedCount()
      }
      else {
        this.$store.dispatch('snackbar/error')
      }
    }).catch(error => {
      this.$store.dispatch('snackbar/error')
    }).finally(() => {
      this.clickedDriverFinishedPlanning = false
      this.driverFinishedPlanningIndicator = false
    })
  }
  isSameDayString(d1: string, d2: string): boolean {
    if (d1 && d2) {
      return this.isSameDay(new Date(d1), new Date(d2))
    } else {
      return false
    }
  }

  isSameDay(d1: Date, d2: Date): boolean {
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate()
  }

  @Watch('$route.query.stop')
  watchStop(id: string | undefined) {
    if (id) {
      const parsedId = Number(id)
      this.stopId = this.planning.stops.find(s => s.id === parsedId)?.id ?? null
    } else {
      this.stopId = null
    }
  }

  sendAttachDetachStatus(stop: GetPlanning_GetPlanning_stops) {
      this.createStatus1Los(
          {
            documents: [],
            statusCreateModels: stop.activities.map(activity => {
              return {
                status: {
                  dimensions: [],
                  documents: [],
                  emballage: null,
                  handover: {
                    arrival: null,
                    start: new Date().getTime(),
                    finish: new Date().getTime()
                  },
                  identifier: '',
                  location: null,
                  message: null,
                  person: null,
                  planningData: {
                    tripIdentifier: this.planning.identifier!!,
                    stopIdentifier: stop.identifier!!,
                    activityIdentifier: activity.identifier!!
                  },
                  properties: null,
                  state: StatusState.ACCEPTED,
                  type: activity.statusType
                }
              }
            })
          }
      ).then(result => {
        this.$emit('finishUpdate')
      }).finally(() => {
        this.tempFinish.push({
          id: stop.id,
          activities: stop.activities.map(activity => activity.id)
        })
      })
  }

  openStatus(stop: GetPlanning_GetPlanning_stops) {
    this.$router.push({ path: this.$route.path, query: { ...this.$route.query, stop: stop.id.toString() }})
    this.cachePosition()
  }

  closeStatus() {
    this.$router.replace({ 
      name: 'scan-planning',
      params: { id: this.planning.id.toString() },
      query: { ...(Object.fromEntries(Object.entries(this.$route.query).filter(([k,v]) => k !== 'stop'))) }
    })
    // this.$emit('finishUpdate')
    this.scrollToCachedHeight()
  }

  private hasSharePlanningRequirement() {
    return this.planning.stops.find(stop => stop.activities.find(activity => activity.requirements?.find(item => item.requirement === Requirement.FINISH_PLANNING)))
  }

  cachePosition() {
    const element = this.getElement('stops')
    
    if (element) {
      this.planningState.stop = window.scrollY
    }
  }
  scrollToCachedHeight() {
    this.$nextTick(() => {
      const cache = this.planningState.stop
      if (cache) {
        this.$nextTick(() => {
          const element = this.getElement('stops')
          if (element) {
            window.scroll(0, cache)
          }
        })
      }
    })
  }
  getElement(ref: string): Element {
    const reference = this.$refs[ref]
      let element
      if (Array.isArray(reference)) {
        element = reference[0]
      } else {
        element = reference
      }
      // @ts-ignore
      if (element && element.$el) {
        // @ts-ignore
        element = element.$el
      }
      return element
  }

  private consignmentContainsDangerousGoods(consignment: GetShipments_GetShipment_consignments) {
    if (!consignment.indicators) return false
    return consignment.indicators.find((indicator) => indicator === 'HAZARDOUS_RISK')
  }

  private isComplete(stop: GetPlanning_GetPlanning_stops): boolean {
    return stop.activities.find((activity) => !this.isActivityComplete(stop, activity)) === undefined
  }

  /**
   * Checks if there are any not finished LOAD activities (those should be finished first),
   * if there are not any return all unfinished UNLOAD activities
   */
  private getNextActvities(stop: GetPlanning_GetPlanning_stops): GetPlanning_GetPlanning_stops_activities[] {
    let notFinishedUnloadActivities = stop.activities.filter((activity) => !this.isActivityComplete(stop, activity) && activity.action == 'UNLOAD')

    if (notFinishedUnloadActivities.length) {
      return notFinishedUnloadActivities
    } else {
      return stop.activities.filter((activity) => !this.isActivityComplete(stop, activity) && activity.action == 'LOAD')
    }
  }

  private isActivityComplete(stop: GetPlanning_GetPlanning_stops, activity: GetPlanning_GetPlanning_stops_activities): boolean {
    if (this.tempFinish.find(tempFinish => tempFinish.id === stop.id) !== undefined && this.tempFinish.find(tempFinish => tempFinish.id === stop.id)!!.activities.includes(activity.id)) {
      return true
    }

    if (!activity.consignment) {
      return (activity.statuses.find(status => status.type === activity.statusType)) !== undefined
    }
    else {

    const consignment = this.findConsignment(this.planning, activity.consignment)!!
    let actorStage = consignment.actors.findIndex((actor) => actor.company.actorId === consignment.shipment!!.actorId)

    if (
      [
        ...(consignment?.actors[actorStage].progresses?.map((progress) => progress.status)
          ? consignment.actors[actorStage].progresses?.map((progress) => progress.status)!!
          : []),
        consignment?.actors[actorStage].pickup.status,
        consignment?.actors[actorStage].delivery.status,
      ].find(
        (status) =>
          status &&
          status.type === activity.statusType &&
          status.state !== StatusState.NOT_READY &&
            (this.planning.identifier ?
              (status.planningData?.activityIdentifier === activity.identifier &&
              status.planningData?.stopIdentifier === stop.identifier &&
              status.planningData?.tripIdentifier === this.planning.identifier)
          : true)
      )
    ) {
      return true
    }
    }

    return false
  }

  private getActions(stop: GetPlanning_GetPlanning_stops): any[] {
    let actions: any[] = []
    stop.activities.forEach((activity) => {
      if (!actions.find((value) => value == activity.action)) {
        actions.push(activity.action)
        if (actions.length === 2) return actions
      }
    })
    return actions
  }

  private hasNextActorAccepted(actorStage: number, statusses: GetPlanning_GetPlanning_consignments_actors[]): boolean {
    let test = statusses
    if (actorStage >= statusses.length - 1) {
      return false
    } else {
      return test.slice(actorStage + 1).find((status) => status?.pickup) !== undefined
    }
  }

  private getNotesForActivity(activity: GetPlanning_GetPlanning_stops_activities): string | null | undefined {
    return activity.action === 'LOAD'
      ? this.getInfoForActorIfExists(activity)?.pickup?.instructions
      : this.getInfoForActorIfExists(activity)?.delivery?.instructions
  }

  private getButtonText(stop: GetPlanning_GetPlanning_stops): string {
    return this.hasActivitiesWithConsignment(stop) ? 'scan.start' : stop.activities.find(activity => activity.action === Action.ATTACH) ? 'scan.fields.attach' : 'scan.fields.detach'
  }

  private getContactDetailsForActivity(
    activity: GetPlanning_GetPlanning_stops_activities
  ): GetPlanning_GetPlanning_consignments_actors_pickup_person | null | undefined {
    return activity.action === 'LOAD'
      ? this.getInfoForActorIfExists(activity)?.pickup?.person
      : this.getInfoForActorIfExists(activity)?.delivery?.person
  }

  private getActivitiesWithInfoOrContact(activities: GetPlanning_GetPlanning_stops_activities[]): GetPlanning_GetPlanning_stops_activities[] {
    return activities.filter((activity) => {
      return activity.action === 'LOAD'
        ? this.getInfoForActorIfExists(activity)?.pickup?.instructions || this.getInfoForActorIfExists(activity)?.pickup?.person
        : this.getInfoForActorIfExists(activity)?.delivery?.instructions || this.getInfoForActorIfExists(activity)?.pickup?.person
    })
  }

  private findConsignment(planning: GetPlanning_GetPlanning, consignmentId: number): GetPlanning_GetPlanning_consignments | undefined {
    return planning.consignments.find((consignment) => consignment.id === consignmentId)
  }

  private getReferenceForAction(planning: GetPlanning_GetPlanning, consignmentId: number, action: string): string | null {
    let consignment = this.findConsignment(planning, consignmentId)
    return consignment ? this.findReferenceForConsignmentAndActor(consignment, action) : null
  }

  private getInfoForActorIfExists(activity: GetPlanning_GetPlanning_stops_activities): GetPlanning_GetPlanning_consignments_actors | undefined {
    const consignment = activity.consignment ? this.findConsignment(this.planning, activity.consignment) : undefined
    return consignment?.actors.find((actor) => actor.company.actorId === consignment.shipment?.actorId)
  }

  private getStopTimeFrame(stop: GetPlanning_GetPlanning_stops): string {
    return [stop.eta, stop.etd].filter((date) => date).join(' - ')
  }

  @Watch('$store.state.gps')
  postGps(loc: string) {
    // @ts-ignore
    if (window.useGPSLocation) {
      console.log(`Posting gps: ${loc}`)
      this.postOnRouteStatus(loc.split('|')[1].trim())
    }
  }

  private postGPS() {
    // @ts-ignore
    if (window.useGPSLocation) {
      const this2 = this
      this.interval = setInterval(function() {
        this2.getGeoLocation().then(gps => {
          this2.postOnRouteStatus(gps)
        })
      }, this.THREE_MINUTES)
    }
  }

  private postOnRouteStatus(gps: string) {
      const date = new Date()
      const statuses = this.planning.consignments.map(consignment => {
        const  status = this.createBundledStatusCreateModel(null, null, null, {
          arrival: date.getTime(),
          start: date.getTime(),
          finish: date.getTime()
        }, null, StatusType.ON_ROUTE, null, null, gps, null, [], StatusState.ACCEPTED)
        return {
          consignment: consignment.name,
          shipment: consignment.shipment?.identifier!!,
          expeditor: consignment.shipment?.expeditor.id!!,
          status: status
        }
      })

      this.createStatus1({
        statusCreateModels: statuses,
        documents: []
      })
    }

    private hasActivitiesWithConsignment(stop: GetPlanning_GetPlanning_stops): boolean {
      return stop.activities.filter(activity => activity.consignment).length !== 0
    }
}

interface LocalStorageClickCount {
  id: number
  count: number
}

interface LocalStorageTempFinish {
  id: number
  updated: number
  stops: LocalStorageTempFinishStop[]
}

export interface LocalStorageTempFinishStop {
  id: number
  activities: number[]
}

