import { Component, Vue } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { ApolloQueryResult } from 'apollo-client'
import {ListPackagingTypes, ListPackagingTypes_ListPackagingTypes} from "@/generated/ListPackagingTypes";
import {PACKAGING_TYPE_MODEL_FRAGMENT} from "@/modules/graphql/PackagingTypeFragment";

@Component
export default class PackagingTypeService extends Vue {
  private listPackagingTypes = gql`
    ${PACKAGING_TYPE_MODEL_FRAGMENT}
    
    query ListPackagingTypes {
      ListPackagingTypes {
        ...PackagingTypeModelFields
      }
    }
  `

  getPackagingTypes(): Promise<ListPackagingTypes_ListPackagingTypes[]> {
    return new Promise((resolve, reject) => {
      this.$apollo
        .query({
          query: this.listPackagingTypes
        })
        .then((result: ApolloQueryResult<ListPackagingTypes>) => {
          //@ts-ignore
          resolve(result.data.ListPackagingTypes)
        })
        .catch(reason => {
          reject(reason)
        })
    })
  }
}
