





































































































































































































































































































































































































































































































































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator'
import DocumentTools from '@/modules/shipments/models/Document'
import {MapType} from '@/general/models/MapType'
import {Header} from '@/general/models/Vuetify'
import AuthenticationService from '@/general/services/AuthenticationService'
import {DocumentCreateModel, DocumentType, Requirement, RequirementLevel, StatusState} from "@/generated/globalTypes";
import {
  GetPlanning_GetPlanning_consignments,
  GetPlanning_GetPlanning_stops,
  GetPlanning_GetPlanning_stops_activities_requirements
} from "@/generated/GetPlanning";
import {CreateStatusData, Emballage, Property, StatusDataDocument} from "@/modules/scan/components/CreateStatusData";
import {ListPackagingTypes_ListPackagingTypes} from "@/generated/ListPackagingTypes";
import InputPackaging from "@/modules/scan/components/InputPackaging.vue";
import {PlanningUtil} from "@/modules/planning/util/PlanningUtil";
import {GetConsignment_GetConsignment} from "@/generated/GetConsignment";
import BaseTextField from "@/general/components/general/BaseTextField.vue";
import Compressor from 'compressorjs'

@Component({
  components: {BaseTextField, InputPackaging, InputEmballage: InputPackaging}
})
export default class StatusData extends Mixins(AuthenticationService, PlanningUtil) {
  @Prop({ required: false, default: -1 }) index: number
  @Prop({ required: false, default: -1 }) active: number
  @Prop({ required: false, default: false }) next: boolean
  @Prop({ required: true }) consignments: GetPlanning_GetPlanning_consignments[]
  @Prop({ required: true }) statusData: CreateStatusData
  @Prop({ required: false, default: null }) stop: GetPlanning_GetPlanning_stops
  @Prop({ required: false, default: () => [] }) packagingTypes: ListPackagingTypes_ListPackagingTypes[]
  @Prop({ required: true }) actions: string[]

  statusStateRadioButton: StatusState[] = []
  statusRequirementsEnum = Requirement
  statusRequirementLevelEnum = RequirementLevel
  DocumentType = DocumentType
  panelsIndex = -1
  StatusState = StatusState
  emballageFocus = false

  typeChanged(item: any) {
    item.size = undefined
  }

  mounted() {
    this.watchNext()
  }

  getRequirement(requirement: Requirement): GetPlanning_GetPlanning_stops_activities_requirements | undefined {
    if (this.single) {
      return this.stop.activities[this.index].requirements.find(r => r.requirement === requirement)
    } else {
      return this.stop.activities.flatMap(a => a.requirements).find(r => r.requirement === requirement)
    }
  }

  testRegexForTemperature(event: KeyboardEvent) {
    if (!(event.key === "Backspace" || event.key === "Delete") && !RegExp('[+-.\\d]').test(event.key)) {
      event.preventDefault()
    }
  }

  @Watch('next')
  watchNext() {
    if (this.active === this.index && this.next) {
      let gotoReq: Requirement | null = null
      Object.keys(Requirement).forEach((value) => {
        const req = Requirement[value as keyof typeof Requirement]
        if (this.isRequirementUnsatisfied(req) && !gotoReq) {
          if (req === Requirement.EMBALLAGE_PICKED_UP || req === Requirement.EMBALLAGE_DELIVERED) {
            gotoReq = Requirement.EMBALLAGE
          } else {
            gotoReq = req
          }
        }
      })
      console.log(gotoReq)
      if (gotoReq) {
        this.setPanelIndex(gotoReq)
      }
    }
  }

  // getImg = (image: Document) => {
  //   return `data:${image.extension};base64,${image.value}`
  // }

  created() {
    this.statusData.data.map(data => {
      this.statusStateRadioButton.push(data.state)
    })
  }
  get totalEmballage(): number {
    // @ts-ignore
    const amounts: number[] = this.statusData.data[this.index].pickedUpEmballage.map(e => parseInt(e.amount)).concat(this.statusData.data[this.index].deliveredEmballage.map(e => parseInt(e.amount)))
    return amounts.reduce((a, b) => a + b, 0)
  }

  get emballageHeaders() {
    return [new Header('erp.packaging', 'packaging', false), new Header('common.amount', 'amount', false)]
  }

  setPanelIndex(req: Requirement) {
    let relevantRequirements: GetPlanning_GetPlanning_stops_activities_requirements[] = this.getStatusRequirementsForStop(this.stop, this.statusData.data)
    let reqs = this.visibleRequirementOrder.filter(requirement => relevantRequirements.find(relevantRequirement => relevantRequirement.requirement === requirement))

    if (req !== Requirement.MANUAL_ACCEPT_CHECK) {
      if(req === Requirement.EMBALLAGE) {
        if (Math.min(reqs.indexOf(Requirement.EMBALLAGE_PICKED_UP), reqs.indexOf(Requirement.EMBALLAGE_DELIVERED)) === -1) {
          this.panelsIndex = Math.max(reqs.indexOf(Requirement.EMBALLAGE_PICKED_UP), reqs.indexOf(Requirement.EMBALLAGE_DELIVERED))
        } else {
          this.panelsIndex = Math.min(reqs.indexOf(Requirement.EMBALLAGE_PICKED_UP), reqs.indexOf(Requirement.EMBALLAGE_DELIVERED))
        }
      } else {
        this.panelsIndex = reqs.indexOf(req)
      }
      setTimeout(() => {
        try {
          this.$vuetify.goTo('.scrollable-panel' + this.index).then(() => {
            if (req === Requirement.EMBALLAGE) {
              this.emballageFocus = !this.emballageFocus
            }
          })
        } catch (e) {
          window.scrollTo(0,0)
        }
      }, 600);
      }
    this.$emit('nextRequirement')
  }

  hasMissingCargo(): boolean {
    return !!this.getMissingCargo().length
  }

  emitEmballage(index: number, packaging: string, data: any, pickup: boolean) {
    const emballage = pickup ? this.statusData.data[index].pickedUpEmballage : this.statusData.data[index].deliveredEmballage
    const i = emballage.findIndex(emb => emb.type == packaging)
    if (i >= 0) emballage.splice(i, 1)
    if (data) {
      emballage.push(new Emballage(packaging, data))
    }
    this.emitDataSingle(index, `${pickup ? 'pickedUp' : 'delivered'}Emballage`, emballage, false)
    this.emitZeroEmballageForConsignment(index, false)
  }

  getPropertyValueForContainer(consignment: GetConsignment_GetConsignment, packagingType: string) {
    new Emballage(packagingType, Number(consignment.containers[0].properties?.items?.find(property => property.key === packagingType)?.value!!))
    return consignment.containers[0].properties?.items?.find(property => property.key === packagingType)?.value ? new Emballage(packagingType, Number(consignment.containers[0].properties?.items?.find(property => property.key === packagingType)?.value!!)) : Object.assign({}, { key: packagingType, value: 0})
  }

  emitMutations(index: number, packaging: string, data: any) {
    const properties = this.statusData.data[index].mutations
    const i = properties.findIndex(mutations => mutations.key == packaging)
    if (i >= 0) properties.splice(i, 1)

    if (data) {
      properties.push(new Property(packaging, data))
    }
    this.emitDataSingle(index, 'properties', properties, false)
  }

  getMissingCargo(): string[] {
    if (this.index === -1) {
      let barcodes: string[] = []
      for (let i = 0; i < this.consignments.length; i++) {
        barcodes = barcodes.concat(this.doGetMissingCargo(i))
      }
      return barcodes
    } else {
      return this.doGetMissingCargo(this.index)
    }
  }

  doGetMissingCargo(index: number): string[] {
    // const barcodes: string[] = []
    // this.getContainerBarcodes(this.consignments[index]).forEach(value => {
    //   if (this.statusData.data[index].scannedCargo.indexOf(value) < 0) {
    //     barcodes.push(value)
    //   }
    // })
    // return barcodes
    return []
  }

  hasContainerBarcodes(): boolean {
    if (this.index >= 0) {
      return !!this.getContainerBarcodes(this.consignments[this.index]).length
    } else {
      for (let i = 0; i < this.consignments.length; i++) {
        if (this.getContainerBarcodes(this.consignments[i]).length) {
          return true
        }
      }
      return false
    }
  }

  getContainerBarcodes(consignment: GetPlanning_GetPlanning_consignments): string[] {
    // @ts-ignore
    return (consignment.containers ? consignment.containers : []).map(container => container.barcode).filter(barcode => barcode)
  }

  getAllDocumentType(type: DocumentType) {
    if (this.index >= 0) {
      return this.statusData.data[this.index].documents.map(documentIndex => this.statusData.documents[documentIndex]).filter(document => document.type === type)
    } else {
      return this.statusData.documents.filter(document => document.type === type)
    }
  }

  removeFiles(deletedDocument: StatusDataDocument) {
    this.$emit('removeFile', deletedDocument)
  }

  get single(): boolean {
    return this.index !== -1
  }

  private containersToPackagingTypes(index: number): ListPackagingTypes_ListPackagingTypes[] {
    return this.consignments[index].containers.flatMap(container => container.type?.type!!).map((containerType, index) => {
      return { packagingType: containerType!!, id: index, order: index, aliases: null, required: false, __typename: "PackagingTypeModel" }
    })
  }

  private getChangeablePackageTypes(): ListPackagingTypes_ListPackagingTypes[] {
    if (this.containsRequirementByOptional(Requirement.MODIFY_CONTAINER_QUANTITIES, true) && this.containsRequirementByOptional(Requirement.MODIFY_SHELVES_EXTENSIONS, true)) {
      return this.packagingTypes
    } else if (this.containsRequirementByOptional(Requirement.MODIFY_SHELVES_EXTENSIONS, true) && !this.containsRequirementByOptional(Requirement.MODIFY_CONTAINER_QUANTITIES, true)) {
      return this.packagingTypes.filter(packagingType => packagingType.packagingType === "Shelves" || packagingType.packagingType === "Extensions")
    } else if (!this.containsRequirementByOptional(Requirement.MODIFY_SHELVES_EXTENSIONS, true) && this.containsRequirementByOptional(Requirement.MODIFY_CONTAINER_QUANTITIES, true)) {
      return this.packagingTypes.filter(packagingType => packagingType.packagingType !== "Shelves" && packagingType.packagingType !== "Extensions")

    }
    return []
  }

  private getInitValueChangeablePackageTypes(index: number) {
    return this.getChangeablePackageTypes().map(packagingType => {
      let mutation = this.statusData.data[index].mutations.find(property => property.key === packagingType.packagingType)
      let container = this.consignments[index].containers.find(container => container.type?.type === packagingType.packagingType || packagingType.aliases?.includes(container.type?.type!!))
      let properties = this.consignments[index].containers.flatMap(container => container.properties?.items).filter(properties => properties).find(property => property?.key === packagingType.packagingType)
      return mutation ? Object.assign({}, {type: mutation!!.key, amount: mutation!!.value}) : (container ? Object.assign({}, {type: packagingType.packagingType, amount: container.quantity}) :
      properties ? Object.assign({}, {type: properties!!.key, amount: properties!!.value}) : null)
    }).filter(init => init)
  }



  private containersToPackagingTypesInitValues(consignment: GetConsignment_GetConsignment, packagingType: string): Emballage {
    let foundContainer = consignment.containers.find(container => container.type?.type === packagingType)!!
    return new Emballage(foundContainer.type?.type!!, foundContainer.quantity)
  }


  zeroEmballage() {
    // If zero emballage; Search all container types en properties for matching values with the packagingTypes array
    // If there is a match we should emit a 0 value for the emballage
    if (this.single) {
      this.emitZeroEmballageForConsignment(this.index, true)
    } else {
      this.consignments.forEach((consignment, index) => {
          this.emitZeroEmballageForConsignment(index, true)
      })
    }
  }

  clearZeroEmballage() {
    if (this.requires(Requirement.EMBALLAGE_PICKED_UP, false)) {
      this.emitData('pickedUpEmballage', [])
    }
    if (this.requires(Requirement.EMBALLAGE_DELIVERED, false)) {
      this.emitData('deliveredEmballage', [])
    }

    this.emitData('clickedZeroEmballage', false, false)
  }

  emitZeroEmballageForConsignment(index: number, value: boolean) {
    this.emitDataSingle(index, 'clickedZeroEmballage', value, false)
  }

  emitFiles(documents: DocumentCreateModel[], type: DocumentType) {
      this.$emit(
        'files',
        documents
          .map(document => {
            let statusDataDocument = new StatusDataDocument(
              document.name,
              document.base64,
              type,
              document.mime,
            )
            return statusDataDocument
          })
      )
  }


  emitData(path: string, data: any, joinArray: boolean = false) {
    if (this.single) {
      this.emitDataSingle(this.index, path, data, joinArray)
    } else {
      this.statusData.data.forEach((value, index) => {
        if (value.selected) {
          this.emitDataSingle(index, path, data, joinArray)
        }
      })
    }
  }

  private emitDataSingle(index: number, path: string, data: any, joinArray: boolean) {
    this.$emit('data', {
      index: index,
      path: path,
      data: joinArray ? (this.statusData.data[index] as MapType)[path].concat(data) : data
    })
  }

  requires(requirement: Requirement, optional: boolean): boolean {
    if (this.single) {
      return this.findRequirementInActivityByOptional(this.stop.activities[this.index], this.statusData.data[this.index], requirement, optional)
    } else {
      for (let i = 0; i < this.statusData.data.length; i++) {
        if (this.findRequirementInActivityByOptional(this.stop.activities[i], this.statusData.data[i], requirement, optional)) {
          return true
        }
      }
      return false
    }
  }

  isRequirementUnsatisfied(requirement: Requirement): boolean {
    if (this.single) {
      return this.isStatusRequirementUnsatisfied(this.statusData, this.getStatusRequirementsForActivity(this.stop.activities[this.index], this.statusData.data[this.index]), requirement, this.index, this.packagingTypes, this.consignments)
    } else {
      for (let i = 0; i < this.statusData.data.length; i++) {
        if (this.isStatusRequirementUnsatisfied(this.statusData, this.getStatusRequirementsForActivity(this.stop.activities[i], this.statusData.data[i]), requirement, i, this.packagingTypes, this.consignments)) {
          return true
        }
      }
      return false
    }
  }

  containsRequirementByOptional(requirement: Requirement, optional: boolean): boolean {
    if (this.single) {
      return this.findRequirementInActivityByOptional(this.stop.activities[this.index], this.statusData.data[this.index], requirement, optional)
    } else {
      for (let i = 0; i < this.statusData.data.length; i++) {
        if (this.findRequirementInActivityByOptional(this.stop.activities[i], this.statusData.data[i], requirement, optional)) {
          return true
        }
      }
      return false
    }
  }

  containsVisibleRequirementsByOptional(optional: boolean): boolean {
    if (this.single) {
      return this.hasActivityVisibleRequirements(this.stop.activities[this.index], this.statusData.data[this.index], optional)
    } else {
      for (let i = 0; i < this.statusData.data.length; i++) {
        if (this.hasActivityVisibleRequirements(this.stop.activities[i], this.statusData.data[i], optional)) {
          return true
        }
      }
      return false
    }
  }

  async filesToDocuments(value: FileList): Promise<DocumentCreateModel[]> {
    const documents: DocumentCreateModel[] = []
    await Promise.all(
      Array.prototype.map.call(value, (file: File) => new Promise((resolve) => {
        if (!this.$store.state.zebra && file.type.startsWith('image')) {
          new Compressor(file, {
            quality: 0.6, // the quality of the image, max is 1,
            convertSize: 2000000, // 2mb

            success(compressedFile: File | Blob) {
              file = compressedFile as File
              // @ts-ignore
              const document: DocumentCreateModel = { type: null, mime: null, name: null, base64: null, meta: null }
              DocumentTools.fromFile(file, document).then(() => {
                documents.push(document)
                resolve()
              })
            }
          })
        }
      })))
    return documents
  }
}
