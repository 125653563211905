import { Component, Vue } from 'vue-property-decorator'

interface GPSLocationProvider {
  getLocation(): Promise<string>
}

class PWAGPSLocationProvider implements GPSLocationProvider {
  getLocation(): Promise<string> {
    return Promise.race([this.wait(10000), new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
          (location: Position) => {
            resolve(location.coords.latitude + ',' + location.coords.longitude)
          },
          () => {
            reject('Location not available')
          }
      )
    })]) as Promise<string>
  }

  wait(ms: number): Promise<unknown> {
    return new Promise((_, reject) => {
      setTimeout(() => reject('Location not available, timeout exceeded'), ms);
    });
  }
}

@Component
class NativeAppGPSLocationProvider extends Vue implements GPSLocationProvider {
  window: any
  gps: string | null = null


  created() {

    // @ts-ignore
    window.requestLocationResponse = (coordinates: string): string => {
      const log = `Requested location response: "${coordinates}"`
      this.gps = coordinates
      return log
    }
  }

  getLocation(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      setTimeout(() => {
        let watcher = this.$watch('gps', (n, o) => {
          watcher()
          this.gps = null
          resolve(n)
        })
        // @ts-ignore
        nativeAppLocationService.requestLocation()
      }, 0);
    })
  }
}

@Component
export default class GPSMixin extends Vue {
  private gpsLocationProvider: GPSLocationProvider

  created() {
    if (this.$store.state.nativeMode) {
      this.gpsLocationProvider = new NativeAppGPSLocationProvider()
    } else {
      this.gpsLocationProvider = new PWAGPSLocationProvider()
    }
  }

  getGeoLocation(): Promise<string> {
    return this.gpsLocationProvider.getLocation()
  }
}
