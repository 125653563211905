

























import {Component, Mixins, Prop} from 'vue-property-decorator'
import PlanningStops from '@/modules/planning/views/PlanningStops.vue'
import BaseCheckBox from '@/general/components/general/BaseCheckBox.vue'
import InfoCard from '@/general/components/common/InfoCard.vue'
import Tabs from '@/general/components/common/Tabs.vue'
import {
  GetPlanning_GetPlanning,
} from "@/generated/GetPlanning";
import BaseTextField from '@/general/components/general/BaseTextField.vue'
import {ShipmentCompanyUpdateModel} from "@/generated/globalTypes";
import FormMixin from "@/general/mixins/FormMixin";
import ConsignmentService from "@/modules/shipments/services/ConsignmentService";
import BaseForm from "@/general/components/general/BaseForm.vue";
@Component({
  components: {
    BaseForm,
    BaseTextField,
    Tabs,
    BaseCheckBox,
    PlanningStops,
    InfoCard
  }
})
export default class UpdateAddress extends Mixins(FormMixin, ConsignmentService) {
  @Prop() planning: GetPlanning_GetPlanning
  company: ShipmentCompanyUpdateModel | null = null

  validator(): any {
  }

  created() {
    this.company = this.planning.consignments[0].actors[0].company
  }

  submit() {
    console.log(123)
    Promise.all(this.planning.consignments.map(consignment => {
      let duplicateConsignment = Object.assign({}, consignment)
      // @ts-ignore
      delete duplicateConsignment.shipment
      let id = duplicateConsignment.actors[0].company.id
      const copy = Object.assign({}, this.company)
      copy.id = id
      // @ts-ignore
      duplicateConsignment.actors[0].company = copy

      this.updateConsignment(duplicateConsignment)
    })).then((values) => {
      this.$store.dispatch('snackbar/saved')
    })
  }
}
