
































import {Component, Mixins} from 'vue-property-decorator'
import PlanningStops from '@/modules/planning/views/PlanningStops.vue'
import BaseCheckBox from '@/general/components/general/BaseCheckBox.vue'
import InfoCard from '@/general/components/common/InfoCard.vue'
import Tabs from '@/general/components/common/Tabs.vue'
import AccessCodeOverview from '@/modules/planning/views/AccessCodeOverview.vue'
import PlanningService from "@/modules/planning/PlanningService";
import {GetPlanning_GetPlanning} from "@/generated/GetPlanning";
import PlanningAccessCodeService from "@/modules/planning/PlanningAccessCodeService";
import BaseTextField from '@/general/components/general/BaseTextField.vue'
import UpdateAddress from "@/modules/planning/views/UpdateAddress.vue";
import {DocumentType, VehicleType} from "@/generated/globalTypes";
@Component({
  components: {
    UpdateAddress,
    BaseTextField,
    AccessCodeOverview,
    Tabs,
    BaseCheckBox,
    PlanningStops,
    InfoCard
  }
})
export default class Detail extends Mixins(PlanningService, PlanningAccessCodeService) {
  tab: number = 0
  getAllAccessCodesFunction = this.getPlanningAccessCodeOverview
  createAccessCodeFunction = this.createPlanningAccessCode
  // updateAccessCode = updateAccessCode
  // sendAccessCode = sendAccessCode
  // deleteAccessCode = deleteAccessCode
  licensePlate = ''

  ecmrVehicleType = VehicleType.TRUCK

  get planning(): GetPlanning_GetPlanning {
    return this.$store.state.transport.planning
  }

  created() {
    this.fetchPlanning()
    this.$websocket.eventEmitter.on('PLANNING', this.subscription)
  }
  destroyed() {

    this.$websocket.eventEmitter.removeListener('PLANNING', this.subscription)
  }

  fetchPlanning() {
    this.getPlanning(Number(this.$route.params.id)).then(result => {
      this.$store.dispatch('transport/setPlanning', result)
    })
  }
  subscription(id: number) {
    if (id === Number(this.$route.params.id)) {
      this.fetchPlanning()
    }
  }

  sendLicensePlate() {
    this.setLicensePlate(this.planning?.id!, this.licensePlate).then(result => {
      this.fetchPlanning()
    })
  }

  hasECMR(): boolean {
    if (this.planning) {
      return this.planning.consignments.flatMap(c => c.documents).filter(d => d?.type ===  DocumentType.eCMR).length > 0
    } else {
      return false
    }
  }

  private getAccessCode(accessCodeSecret: string): string {
      return `${window.location.origin}/jobs?accessCode=${accessCodeSecret}`
  }
}
