















import {Component, Prop, Vue} from 'vue-property-decorator'
import ShipmentArticles from '@/modules/shipments/views/detail/components/ShipmentArticles.vue'
import InfoCard from '@/general/components/common/InfoCard.vue'
import CurrentStage from '@/modules/shipments/views/detail/components/CurrentStage.vue'
import Popup from "@/general/components/common/Popup.vue";
import {Emballage} from "@/modules/scan/components/CreateStatusData";

@Component({
  components: {
    Popup,
    CurrentStage,
    ShipmentArticles,
    InfoCard
  }
})
export default class ScanEmballageOverview extends Vue {
  @Prop() emballage: Emballage[]
}
