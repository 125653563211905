













import { Component, Prop, Vue } from 'vue-property-decorator'
import Popup from '@/general/components/common/Popup.vue'
@Component({
  components: { Popup }
})
export default class SurePopup extends Vue {
  @Prop() value: boolean
  @Prop() header: string

  choose(answer: boolean = false) {
    if (answer) this.$emit('agreed', answer)
    this.$emit('input', false)
  }
}
