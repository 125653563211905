






























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import InfoCard from '@/general/components/common/InfoCard.vue'
import { Header } from '@/general/models/Vuetify'
import {ListPackagingTypes_ListPackagingTypes} from "@/generated/ListPackagingTypes";
import {Data, Emballage} from "@/modules/scan/components/CreateStatusData";
import BaseDataTable from "@/general/components/general/BaseDataTable.vue";
import { GetPlanning_GetPlanning_consignments } from '@/generated/GetPlanning';

@Component({
  components: {
    BaseDataTable,
    InfoCard
  }
})
export default class InputPackaging extends Vue {
  @Prop({ required: true }) consignments: GetPlanning_GetPlanning_consignments[]
  @Prop({ required: true }) packagingTypes: ListPackagingTypes_ListPackagingTypes[]
  @Prop({ required: false, default: [] }) pickupInitValues: Emballage[]
  @Prop({ required: false, default: [] }) deliveryInitValues: Emballage[]
  @Prop({ required: true }) pickup: boolean
  @Prop({ required: true }) delivery: boolean
  @Prop({ required: true, default: false }) pickupUnsatisfied: boolean
  @Prop({ required: true, default: false }) deliveryUnsatisfied: boolean
  @Prop({ required: true }) emballageFocus: boolean

  mounted() {
    this.focusEmballage()
  }

  @Watch('emballageFocus')
  focusEmballage() {
    this.$nextTick(() => {
      const types = this.packagingTypes.filter(p => p.required)
      let req: string | undefined = undefined
      types.forEach(t => {
        req = req || this.findEmballage(t.id)
      })
      req = req || 'pickup' + this.packagingTypes[0].id
      if (req) {
        // @ts-ignore
        this.$refs[req].focus()
      }
    })
  }

  findEmballage(id: number): string | undefined {
    const pickup = 'pickup' + id
    const pickupRef = this.$refs[pickup]
    // @ts-ignore
    if (!pickupRef || isNaN(pickupRef.value) || pickupRef.value === '') {
      return pickup
    }
    const delivery = 'delivery' + id
    const deliveryRef = this.$refs[delivery]
    // @ts-ignore
    if (!deliveryRef || isNaN(deliveryRef.value) || deliveryRef.value === '') {
      return 'delivery' + id
    }
  }

  emitPickupEvent(index: number, packagingType: string, value: number) {
      let output: InputPackagingEvent = { packagingType: packagingType, value: value }
      this.$emit('inputPickupPackaging', output)
  }

  emitDeliveryEvent(index: number, packagingType: string, value: number) {
    let output: InputPackagingEvent = { packagingType: packagingType, value: value }
    this.$emit('inputDeliveryPackaging', output)
  }

  getPickupInitValue(packagingType: string): number | undefined {
    return this.pickupInitValues.find(value => value.type === packagingType)?.amount
  }

  getDeliveryInitValue(packagingType: string): number | undefined {
    return this.deliveryInitValues.find(value => value.type === packagingType)?.amount
  }

  get emballageHeaders() {
    return [
        new Header('erp.packaging', 'packaging', false),
        this.pickup ? new Header('Pickup', 'amount', false) : null,
        this.delivery ? new Header('Deliver', 'packaging', false) : null
    ].filter(header => header)
  }

  get isMobile(): boolean {
    return window.innerWidth < 960
  }
}

interface Pair {
  key: string
  value: number
}

interface InputPackagingEvent {
  packagingType: string
  value: number
}
