import gql from 'graphql-tag'

export const PACKAGING_TYPE_MODEL_FRAGMENT = gql`
  fragment PackagingTypeModelFields on PackagingTypeModel {
      id
      order
      packagingType
      aliases,
      required
  }
`
