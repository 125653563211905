


























































































































































































































































































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator'
import FormMixin from '@/general/mixins/FormMixin'
import InfoCard from '@/general/components/common/InfoCard.vue'
import DocumentTools from '@/modules/shipments/models/Document'
import SaveButton from '@/general/components/common/SaveButton.vue'
import {AxiosError} from 'axios'
import i18n from '@/i18n'
import Popup from '@/general/components/common/Popup.vue'
import VueSignaturePad from 'vue-signature-pad'

import {emailValidator} from '@/modules/users/models/User'

import ScanComponent from '@/modules/scan/views/ScanComponent.vue'
import {
  ConsignmentId,
  CreateStatusData,
  Data,
  Emballage,
  Property,
  StatusDataDocument
} from '@/modules/scan/components/CreateStatusData'
import {MapType} from '@/general/models/MapType'
import StatusData from '@/modules/scan/components/StatusData.vue'
import {deserialize, serialize} from '@/plugins/utils'
import AuthenticationService from '@/general/services/AuthenticationService'
import BaseCheckBox from '@/general/components/general/BaseCheckBox.vue'
import BaseTextArea from '@/general/components/general/BaseTextArea.vue'
import BaseTextField from '@/general/components/general/BaseTextField.vue'
import GPSMixin from '@/general/mixins/GPSMixin'
import StatusService from '@/modules/shipments/services/StatusService'
import {
  BundledStatusCreateModel,
  BundledStatusesCreateModel,
  BundledStatusesIDCreateModel,
  DocumentType,
  MapStringIntInput,
  PairStringStringInput,
  PersonCreateModel,
  PlanningDataCreateModel,
  Requirement,
  RequirementLevel,
  StatusIDCreateModel,
  StatusState,
  StatusType
} from '@/generated/globalTypes'
import {
  GetPlanning_GetPlanning,
  GetPlanning_GetPlanning_consignments,
  GetPlanning_GetPlanning_stops,
  GetPlanning_GetPlanning_stops_activities_requirements
} from '@/generated/GetPlanning'
import {GetShipments_GetShipment_consignments} from '@/generated/GetShipments'
import PackagingTypeService from '@/modules/packaging/PackagingTypeService'
import {ListPackagingTypes_ListPackagingTypes} from '@/generated/ListPackagingTypes'
import {PlanningUtil} from '@/modules/planning/util/PlanningUtil'
import ScanEmballageOverview from "@/modules/scan/views/ScanEmballageOverview.vue";
import {GetAbout_GetAbout} from '@/generated/GetAbout'
import SurePopup from "@/general/components/common/SurePopup.vue";

@Component({
  components: {
    SurePopup,
    ScanEmballageOverview,
    BaseTextField,
    BaseTextArea,
    BaseCheckBox,
    InfoCard,
    SaveButton,
    Popup,
    VueSignaturePad,
    StatusData,
    ScanComponent
  }
})
export default class StatusUpdate extends Mixins(FormMixin, AuthenticationService, GPSMixin, StatusService, PackagingTypeService, PlanningUtil) {
  @Prop({ required: true }) stop: GetPlanning_GetPlanning_stops
  @Prop({ required: true }) planning: GetPlanning_GetPlanning
  @Prop({ required: false, default: 0 }) acceptIndex: number


  private SINGLE_ACTIVITY: number = 1
  panel: number = -1
  next: boolean = false
  overviewNext: boolean = false

  sog: number = -2
  person: PersonCreateModel = {}
  scan: number = -2
  hasAnyRequirements: boolean = true

  location = ''
  acceptShipment = false
  sendingStatusesToBackend: boolean = false
  finishedStop = false

  statusData: CreateStatusData = new CreateStatusData([], [], undefined)

  locationNotAvailable: boolean = false
  acceptWithNoInternet: Boolean = false
  openFinalEmballageAndMissingCargoPopUp = false
  optionalZeroEmballageMessage = ''
  optionalMissingCargoMessage = ''

  missingCargoCache: Map<number, string[]> = new Map()

  packagingTypes: ListPackagingTypes_ListPackagingTypes[] = []

  arrival = new Date()
  start = this.arrival

  statusRequirementsEnum = Requirement
  statusRequirementLevelEnum = RequirementLevel
  statusStates = StatusState

  showStopInfo = false
  surePopup = false

  mounted() {
    if (this.unfinishedConsignmentCount === 1) {
      this.gotoRequirements()
    }
  }

  activityInfo(index: number): string {
    const action = this.stop.activities[index].action
    const consignment = this.getConsignments[index]
    const crossdock = this.crossDockStatusTypes.includes(this.stop.activities[index].statusType)
    
    const customDisplay = this.$store.state.systemSettings.find((setting: GetAbout_GetAbout) => setting.key === 'ActivityInfo')?.value
    if (customDisplay) {
      return eval(customDisplay)
    } else {
      return this.getActivityIdentifierLine(crossdock, action, consignment!)
    }
  }

  get actions() {
    return this.stop.activities.map((activity) => activity.action)
  }

  created() {
    this.$store.commit('isOnStatusUpdate', true)
    window.scrollTo({ top: 0 })

    this.initPackagingTypes()
    this.initStatusData()


    // Get location after filling statusdata is important: Else we might send the 'Activity started' progress statuses too soon
    this.getLocation()

    if (this.$store.state.barcode) {
      this.processContainer(this.$store.state.barcode, true)
    }
  }

  private backButton() {
    if (this.stop.activities.find((activity, index) => this.hasActivityStartedRequirement(index))) {
      this.surePopup = true
    } else {
      this.backButtonExecution()
    }
  }

  private backButtonExecution() {
    this.$emit('close')
  }

  beforeDestroy() {
    if (!this.finishedStop) {
      this.sendActivityStartedCanceled()
    }
  }

  private initPackagingTypes() {
    this.getPackagingTypes().then(packingTypes => {
      this.packagingTypes = packingTypes.sort((a: ListPackagingTypes_ListPackagingTypes, b: ListPackagingTypes_ListPackagingTypes) => a.order - b.order)
      localStorage.setItem('packagingTypes', JSON.stringify(this.packagingTypes))
    }).catch(error => {
      if (localStorage.getItem('packagingTypes')) {
        this.packagingTypes = (JSON.parse(localStorage.getItem('packagingTypes')!!) as ListPackagingTypes_ListPackagingTypes[]).sort(
            (a: ListPackagingTypes_ListPackagingTypes, b: ListPackagingTypes_ListPackagingTypes) => a.order - b.order
        )
      }
    })
  }

  private getInitialStatusState(consignment: GetPlanning_GetPlanning_consignments, index: number): StatusState {
    return this.getStatusRequirementsForActivity(this.stop.activities[index], this.statusData.data[index]).find(test => test.requirement == Requirement.MANUAL_ACCEPT_CHECK && test.level === RequirementLevel.REQUIRED) ? StatusState.DECLINED
        : StatusState.ACCEPTED
  }

  private initStatusData() {
    this.statusData = new CreateStatusData([], [], undefined)
    this.statusData.stopId = this.stop.id
    let cachedStatusData: CreateStatusData = new CreateStatusData([], [], this.stop.id)
    if (localStorage.getItem('statusData')) {
      cachedStatusData = deserialize(cachedStatusData, JSON.parse(localStorage.getItem('statusData')!!))
    }

    this.getConsignments.forEach((consignment, index) => {
      const foundData = cachedStatusData.data.filter(
        data =>
          cachedStatusData.stopId === this.stop.id &&
          data.consignmentIdentifier.expeditor === consignment.shipment?.expeditor.id &&
          data.consignmentIdentifier.shipmentIdentifier === consignment.shipment?.identifier &&
          data.consignmentIdentifier.consignmentIdentifier === consignment.name
      )

      let planningid = undefined
      if (this.stop && this.stop.identifier) {
        // Check for identifier since 'old' shipments may not have this identifier yet when we used hardcoded planning identifiers in TEP
        let activities = this.stop.activities.filter(activity => activity.consignment === consignment.id)

        if (activities.length) {
          // planningid = new PlanningId(this.planning?.identifier!!, this.stop.identifier!!, activities[0].identifier!!)
          planningid = undefined
        }
      }

      if (foundData.length) {
        let data = foundData[0]
        this.addStatusData(data.message, data.scannedCargo, data.deliveredEmballage, data.pickedUpEmballage, data.consignmentIdentifier, planningid, [], [], null, data.state, data.clickedZeroEmballage)
      } else {
      this.addStatusData(
        '',
          this.getStatusRequirementsForActivity(this.stop.activities[index], this.statusData.data[index]).find(test => test.requirement == Requirement.SCAN && test.level === RequirementLevel.REQUIRED) ? []
          : this.getContainerBarcode(consignment),
        [],
        [],
        new ConsignmentId(consignment.shipment?.identifier!!, consignment.name, consignment.shipment?.expeditor.id!!),
        planningid,
          [],
          [],
          null,
          this.getInitialStatusState(consignment, index),
          false
      )
      }
    })
  }

  sendActivityStartedCanceled() {
    const statuses: StatusIDCreateModel[] = this.getConsignments
        .map((consignment, index) => {
          if (this.hasActivityStartedRequirement(index)) {
            let statusType: StatusType
            // Convert the statustypes to the matching Started action statustype
            switch(this.getStatusType(index)) {
              case StatusType.PICKUP: statusType = StatusType.PICKUP_STARTED_CANCELED; break;
              case StatusType.DELIVERY: statusType = StatusType.DELIVERY_STARTED_CANCELED; break;
              case StatusType.CROSSDOCK_DELIVERY: statusType = StatusType.CROSSDOCK_DELIVERY_STARTED_CANCELED; break;
              case StatusType.CROSSDOCK_PICKUP: statusType = StatusType.CROSSDOCK_PICKUP_STARTED_CANCELED; break;
              default: console.log('Error: Unknown Statustype to map a Started action for')
            }

            return this.createStartedOrCanceledStatus(consignment, index, statusType!!)
          }
        })
        .filter(status => status) as StatusIDCreateModel[]

    if (statuses.length) {
      this.createStatus1({
        statusCreateModels: statuses,
        documents: []
      })
    }
  }


  sendActivityStarted() {
    const statuses: StatusIDCreateModel[] = this.getConsignments
      .map((consignment, index) => {
        if (this.hasActivityStartedRequirement(index)) {
          let statusType: StatusType
          // Convert the statustypes to the matching Started action statustype
          switch(this.getStatusType(index)) {
            case StatusType.PICKUP: statusType = StatusType.PICKUP_STARTED; break;
            case StatusType.DELIVERY: statusType = StatusType.DELIVERY_STARTED; break;
            case StatusType.CROSSDOCK_DELIVERY: statusType = StatusType.CROSSDOCK_DELIVERY_STARTED; break;
            case StatusType.CROSSDOCK_PICKUP: statusType = StatusType.CROSSDOCK_PICKUP_STARTED; break;
            default: console.log('Error: Unknown Statustype to map a Started action for')
          }
          return this.createStartedOrCanceledStatus(consignment, index, statusType!!)
        }
      })
      .filter(status => status) as StatusIDCreateModel[]

    if (statuses.length) {
      this.createStatus1({
        statusCreateModels: statuses,
        documents: []
      })
    }
  }

  private hasActivityStartedRequirement(index: number): boolean {
    return (this.getStatusRequirementsForActivity(this.stop.activities[index], this.statusData.data[index]).find(requirement => requirement.requirement === Requirement.ACTIVITY_STARTED && requirement.level === RequirementLevel.REQUIRED)) !== undefined
  }

  private createStartedOrCanceledStatus(consignment: GetPlanning_GetPlanning_consignments, index: number, statusType: StatusType): StatusIDCreateModel {
    let status = this.createBundledStatusCreateModel(null, null, null, {
      arrival: null,
      start: this.start.getTime(),
      finish: null
    }, null, statusType!!, this.getPlanningData(index), null, this.location, null, [], StatusState.ACCEPTED)

    return {
      consignment: consignment.name,
      shipment: consignment.shipment?.identifier!!,
      expeditor: consignment.shipment?.expeditor.id!!,
      status: status
    }
  }

  combineEmballage(accu: Emballage[], curr: Emballage[]) {
    const arr: Emballage[] = JSON.parse(JSON.stringify(accu))
    curr.forEach(emb => {
      const currEmb = arr.find(currEmb => currEmb.type === emb.type)
      //@ts-ignore
      if (currEmb) currEmb.amount = parseInt(currEmb.amount) + parseInt(emb.amount)
      else arr.push(emb)
    })
    return arr
  }

  get hasEmballage(): boolean {
    return this.pickedUpEmballage.length + this.deliveredEmballage.length > 0
  }

  get totalPickedUpEmballage(): number {
    return this.calculateTotalFromEmballage(this.pickedUpEmballage)
  }

  get totalDeliveredEmballage(): number {
    return this.calculateTotalFromEmballage(this.deliveredEmballage)
  }

  get pickedUpEmballage(): Emballage[] {
    return this.consolidateEmballage(this.statusData.data.flatMap(d => d.pickedUpEmballage))
  }

  get deliveredEmballage(): Emballage[] {
    return this.consolidateEmballage(this.statusData.data.flatMap(d => d.deliveredEmballage))
  }

  private calculateTotalFromEmballage(emballage: Emballage[]): number {
    return emballage.map(e => e.amount).reduce((a, b) => a + b, 0)
  }

  private consolidateEmballage(emballage: Emballage[]): Emballage[] {
    let map = new Map<string, number>()
    emballage.forEach(emb => {
      // @ts-ignore
      map.set(emb.type, parseInt(emb.amount) + parseInt(map.get(emb.type) || 0))
    })
    return Array.from(map, ([key, value]) => new Emballage(key, value))
  }

  private addStatusData(
    message: string,
    scannedCargo: string[],
    deliveredEmballage: Emballage[],
    pickedUpEmballage: Emballage[],
    consignmentId: ConsignmentId,
    planningId: undefined,
    documents: number[],
    properties: Property[],
    person: PersonCreateModel | null,
    state: StatusState,
    clickedZeroEmballage: boolean
  ) {
    this.statusData.data.push(new Data(message, scannedCargo, true, deliveredEmballage, pickedUpEmballage, consignmentId, planningId, documents, properties, person, [], state, clickedZeroEmballage))
  }

  destroyed() {
    this.$store.commit('isOnStatusUpdate', false)
  }

  getLocation() {
    this.locationNotAvailable = false
    this.getGeoLocation()
      .then(result => (this.location = result))
      .catch(reason => (this.locationNotAvailable = true)).finally(() => {
        this.sendActivityStarted()
    })
  }

  getContainerBarcode = (consignment: GetShipments_GetShipment_consignments): string[] =>
    // @ts-ignore
    (consignment.containers ? consignment.containers : []).map(container => container.barcode).filter(barcode => barcode)

  @Watch('$store.state.barcode')
  nativeBarcodeScan(barcode: string) {
    if (barcode) {
      console.log(barcode)
      this.processContainer(barcode)
    }
  }

  beep() {
    this.playAudio('data:audio/wav;base64,UklGRl9vT19XQVZFZm10IBAAAAABAAEAQB8AAEAfAAABAAgAZGF0YU' + Array(1e3).join('123'))
  }

  boop() {
    this.playAudio('data:audio/wav;base64,UklGRl9vT19XQVZFZm10IBAAAAABAAEAQB8AAEAfAAABAAgAZGF0YU' + Array(1e3).join('1231232'))
  }

  playAudio(src: string) {
    new Audio(src).play()
  }

  processContainer(code: string, skipMessage = false) {
    let found = false
    if (this.scan < 0) {
      for (let i = 0; i < this.getConsignments.length; i++) {
        found = this.doProcessContainer(code, i) || found
      }
      if (!this.hasMissingCargo()) {
        this.scan = -2
      }
    } else {
      found = this.doProcessContainer(code, this.scan)
      if (!this.hasMissingCargoIndex(this.scan)) {
        this.scan = -2
      }
    }
    if (!skipMessage) {
      if (found) {
        console.log('Found')
        this.beep()
        this.$store.commit('snackbar/show', {
          color: 'success',
          text: i18n.t(`Scanned: ${code}`)
        })
      } else {
        console.log('Not found')
        this.boop()
        this.$store.commit('snackbar/show', {
          color: 'error',
          text: i18n.t(`Not found: ${code}`)
        })
      }
    }
  }

  doProcessContainer(code: string, index: number): boolean {
    let found = false
    if (this.getContainerBarcode(this.getConsignments[index]).indexOf(code) >= 0) {
      found = true
      if (this.statusData.data[index].scannedCargo.indexOf(code) < 0) {
        this.statusData.data[index].scannedCargo.push(code)
      }
    }
    return found
  }

  hasMissingCargo = (filterNoMessage: boolean = false): boolean => {
    const missing = this.getMissingCargo(filterNoMessage)
    return !!(missing && missing.size)
  }

  hasMissingCargoIndex = (index: number, filterNoMessage: boolean = false): boolean => !!this.getMissingCargoIndex(index, filterNoMessage).length

  getMissingCargo = (filterNoMessage: boolean = false): Map<number, string[]> | null => {
    const missing = new Map<number, string[]>()
    this.getConsignments.forEach((_, index) => {
      const miss = this.getMissingCargoIndex(index, filterNoMessage)
      if (miss.length) {
        missing.set(index, miss)
      }
    })
    return missing.size ? missing : null
  }

  getMissingCargoIndex(index: number, filterNoMessage: boolean = false): string[] {
    const ids: string[] = []
    this.getContainerBarcode(this.getConsignments[index]).forEach(value => {
      if (this.statusData.data[index].scannedCargo.indexOf(value) < 0 && (!filterNoMessage || !this.statusData.data[index].message)) {
        ids.push(value)
      }
    })
    return ids
  }

  updateFiles(event: StatusDataDocument[]) {
    let originalLength = this.statusData.documents.length
    this.statusData.documents = this.statusData.documents.concat(event)
    this.statusData.data.forEach(data => {
      if (data.selected) {
        for (let i = originalLength; i < this.statusData.documents.length; i++) {
          data.documents.push(i)
        }
      }
    })
  }

  removeFile(event: StatusDataDocument) {
    let indexOfDocumentDeleted = this.statusData.documents.findIndex(document => document === event)!!
    this.statusData.documents.splice(indexOfDocumentDeleted, 1)

    this.statusData.data.forEach(data => {
      // If the document is found referenced within data make sure to delete the reference
      if (data.documents.findIndex(value => value === indexOfDocumentDeleted) !== -1) {
        data.documents.splice(
          data.documents.findIndex(value => value === indexOfDocumentDeleted),
          1
        )
      }

      // Update the data document indexes because of the deleted document each document greater than indexOfDocumentDeleted should be subtracted by 1
      data.documents.forEach((documentIndex, index) => {
        if (documentIndex > indexOfDocumentDeleted) {
          data.documents[index] = data.documents[index] - 1
        }
      })
    })
  }

  updateData(event: any) {
    if (event.index >= 0) {
      this.doUpdateData(event)
    } else {
      this.statusData.data.forEach((value: Data, i: number) => {
        if (value.selected) {
          event.index = i
          this.doUpdateData(event)
        }
      })
    }
  }

  doUpdateData(event: any) {
    ;(this.statusData.data[event.index] as MapType)[event.path] = event.data
  }

  validator(): any {
    return { email: emailValidator }
  }

  get getConsignments(): GetPlanning_GetPlanning_consignments[] {
    return this.stop.activities
        .flatMap(activity => activity.consignment)
        .map(consignmentId => this.planning!!.consignments.find(consignment => consignment.id === consignmentId)!!)
  }

  get unfinishedConsignmentCount(): number {
    const consignments = this.getConsignments
    return consignments.filter((c, i) => !this.isFinished(i, consignments)).length
  }

  isFinished(index: number, allConsignments: GetPlanning_GetPlanning_consignments[] = this.getConsignments): boolean {
    const activity = this.stop.activities[index]
    const consignment = allConsignments[index]
    let actorStage = consignment.actors.findIndex((actor) => actor.company.actorId === consignment.shipment!!.actorId)

    return !![
        ...(consignment?.actors[actorStage].progresses?.map((progress) => progress.status)
          ? consignment.actors[actorStage].progresses?.map((progress) => progress.status)!!
          : []),
        consignment?.actors[actorStage].pickup.status,
        consignment?.actors[actorStage].delivery.status,
      ].find(
        (status) =>
          status &&
          status.type === activity.statusType &&
          status.state !== StatusState.NOT_READY &&
            (this.planning.identifier ?
              (status.planningData?.activityIdentifier === activity.identifier &&
              status.planningData?.stopIdentifier === this.stop.identifier &&
              status.planningData?.tripIdentifier === this.planning.identifier)
          : true)
      )
  }

  get isMobile(): boolean {
    return window.innerWidth < 960
  }

  submit(skipLocation = false, scan = false) {
    // The popup needs to appear when: 1. You entered emballage, to show the final overview. 2. You accepted with missing cargo, to specify a message they're messag
    // ,3. when emballage is required but the user didn't specify any. 4. you pr
    if (
      scan ||
      (!this.openFinalEmballageAndMissingCargoPopUp &&
        (this.hasMissingCargo() ||
          this.hasEmballage ||
          (!this.hasEmballage && this.getConsignments.map((consignment, index) => this.requirementUnsatisfied(Requirement.EMBALLAGE, index)).includes(true))))
    ) {
      this.missingCargoCache = this.getMissingCargo()!
      this.openFinalEmballageAndMissingCargoPopUp = true
    } else {
      this.panel = -1

      if (this.optionalZeroEmballageMessage) {
        this.statusData.data.forEach(data => {
          data.message ? (data.message += `, ${this.optionalZeroEmballageMessage}`) : (data.message += this.optionalZeroEmballageMessage)
        })
      }

      if (!skipLocation) {
        this.acceptShipment = true
      } else {
        this.sendStatuses()
      }
    }
  }

  @Watch('location')
  doAcceptShipment1() {
    this.doAcceptShipment()
  }

  @Watch('acceptShipment')
  doAcceptShipment() {
    if (this.location && this.acceptShipment) {
      this.sendStatuses(this.location)
    }
  }

  @Watch('statusData', { deep: true })
  watchStatusData() {
    this.setHasAnyRequirements()
    localStorage.setItem('statusData', JSON.stringify(serialize(this.statusData)))
  }

  @Watch('planning.updated')
  watchPlanningUpdate() {
    this.initStatusData()
  }

  isSameDayString(d1: string, d2: string): boolean {
    if (d1 && d2) {
      return this.isSameDay(new Date(d1), new Date(d2))
    } else {
      return false
    }
  }

  isSameDay(d1: Date, d2: Date): boolean {
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate()
  }

  setHasAnyRequirements() {
    this.hasAnyRequirements = this.findConsignmentRequirements(this.stop, this.statusData, this.packagingTypes, this.getConsignments).flatMap(requirements => requirements).length > 0
  }

  gotoRequirements() {
    // First we check if its a single activity; if it is just open the panel of the single activity since there isn't a
    // multi activity overview panel where you can emit data for all activities at once
    if (this.statusData.data.length > this.SINGLE_ACTIVITY) {
      // We only want to navigate to the overview panel in 2 cases: when the requirement is the SPECIFIY_DECLINED_STATE requirement since this once should always be input on activity lvl,
      // and if only ONE activity has the emballage requirement; since then a user can input it on overview level; with multiple required they should input the emballage for each activity individually
      if (
          this.findConsignmentRequirements(this.stop, this.statusData, this.packagingTypes, this.getConsignments).flatMap(requirements => requirements).find(requirement => requirement.requirement !== Requirement.SPECIFY_DECLINED_STATE) &&
          this.getStatusRequirementsForStop(this.stop, this.statusData.data).filter(requirement => requirement.level === RequirementLevel.REQUIRED && (requirement.requirement === Requirement.EMBALLAGE_PICKED_UP  || requirement.requirement === Requirement.EMBALLAGE_DELIVERED)).length === 1
      ) {
        this.overviewNext = !this.overviewNext
      } else {
        // this is the navigate to individual panel section; we want to navigate to the first activity that does not have the Signature requirement, since that requirement should be input last on overview lvl
        // if we won't filter you first have to emit a signature for an individual activity which is not desired
        this.panel = this.findConsignmentRequirements(this.stop, this.statusData, this.packagingTypes, this.getConsignments).findIndex(requirements => requirements.filter(requirement => requirement.requirement !== Requirement.SIGNATURE).length)
        setTimeout(() => {
          this.$vuetify.goTo(`#a${this.findConsignmentRequirements(this.stop, this.statusData, this.packagingTypes, this.getConsignments).findIndex(requirements => requirements.filter(requirement => requirement.requirement !== Requirement.SIGNATURE).length)}`)
          this.next = !this.next
        }, 600);
      }
    } else {
      // If its a indivual activity just navigate to the first panel
      this.panel = 0
      setTimeout(() => {
        this.$vuetify.goTo(`#a0`)
        this.next = !this.next
      }, 600);
    }
  }


  requirementUnsatisfied(requirement: Requirement, index: number): boolean {
    return this.isStatusRequirementUnsatisfied(this.statusData, this.getStatusRequirementsForActivity(this.stop.activities[index], this.statusData.data[index]), requirement, index, this.packagingTypes, this.getConsignments)
  }

  createStatus(index: number, location: string | undefined = undefined): BundledStatusCreateModel {
    const data = this.statusData.data[index]

    const status = this.createBundledStatusCreateModel(
      data.documents,
        data.deliveredEmballage.length
            ? this.emballageToMapStringInt(data.deliveredEmballage) : (data.clickedZeroEmballage ? this.emballageToMapStringInt(this.mapZeroEmballageValues(index, Requirement.EMBALLAGE_DELIVERED)) : this.emballageToMapStringInt([])),
      data.pickedUpEmballage.length
        ? this.emballageToMapStringInt(data.pickedUpEmballage) : (data.clickedZeroEmballage ? this.emballageToMapStringInt(this.mapZeroEmballageValues(index, Requirement.EMBALLAGE_PICKED_UP)) : this.emballageToMapStringInt([])),
      {
        arrival: this.arrival.getTime(),
        start: this.start.getTime(),
        finish: new Date().getTime()
      },
      data.message,
      this.getStatusType(index),
      this.getPlanningData(index),
      data.mutations.length ? { items: data.mutations.map(key => ({ key: key.key, value: key.value.toString() } as PairStringStringInput)) } : null,
      this.location,
      data.person,
      data.dimensions,
      data.state
    )


    // const missingCargo = this.getMissingCargoIndex(index)
    // if (missingCargo.length) {
    //   if (status.message) {
    //     status.message += '\n'
    //   } else {
    //     status.message = ''
    //   }
    //   status.message += 'Missing cargo:' + missingCargo.join(', ')
    //   let optionalMessage = this.produceOptionalMissingCargoMessage()
    //   if (optionalMessage) {
    //     status.message += '\nReason: ' + optionalMessage
    //   }
    // }
    return status
  }

  private mapZeroEmballageValues(index: number, requirement: Requirement): Emballage[] {
    const consignment: GetPlanning_GetPlanning_consignments = this.getConsignments[index]
    return consignment.containers.flatMap(container => {
      let emballage: Emballage[] = []
      if (this.matchesEmballage(container?.type?.type) && this.findRequirementInActivityByOptional(this.stop.activities[index], this.statusData.data[index], requirement, false)) {
        emballage.push(new Emballage(this.matchesEmballage(container?.type?.type)!!, 0))
      }
      container?.properties?.items?.forEach(item => {
        if (this.matchesEmballage(item.key) && this.findRequirementInActivityByOptional(this.stop.activities[index], this.statusData.data[index], requirement, false)) {
          emballage.push(new Emballage(this.matchesEmballage(item.key)!!, 0))
        }
      })
      return emballage
    })
  }

  private matchesEmballage(type: string | undefined | null): string | undefined {
    return this.packagingTypes.find(packagingType => (packagingType.packagingType === type || (type && packagingType.aliases?.includes(type!!))))?.packagingType
  }

  private getStatusType(index: number): StatusType {
      return this.stop.activities[index].statusType
  }

  private emballageToMapStringInt(emballage: Emballage[]): MapStringIntInput {
    return {
      items: emballage.map(emballage => ({
        key: emballage.type,
        value: emballage.amount
      }))
    }
  }

  getPlanningData(index: number): PlanningDataCreateModel | null {
    if (this.planning && this.stop && this.planning.identifier && this.stop.identifier && this.stop.activities[index].identifier) {
      return {
        tripIdentifier: this.planning?.identifier!!,
        stopIdentifier: this.stop.identifier!!,
        activityIdentifier: this.stop.activities[index].identifier!!
      }
    } else {
      return null
    }
  }



  sendStatuses(location: string | undefined = undefined) {
    this.acceptShipment = false
    this.sendingStatusesToBackend = true

    let indexes: number[] = []

    const bundledStatus: BundledStatusesIDCreateModel = {
      documents: this.statusData.documents.map(document => {
            return {
              "base64": document.base64,
              "meta": "",
              "mime": document.mime,
              "name": document.name,
              "type": document.type
            }
          }
      ),
      // @ts-ignore
      statusCreateModels: this.statusData.data.map((data: Data, index) => {
        if (!this.isFinished(index)) {
          indexes.push(index)
          return {
            consignment: data.consignmentIdentifier.consignmentIdentifier,
            shipment: data.consignmentIdentifier.shipmentIdentifier,
            expeditor: data.consignmentIdentifier.expeditor,
            status: this.createStatus(index, location)
          } as StatusIDCreateModel
        } else return null
      }).filter(s => !!s)
    }

    this.createStatus1(bundledStatus).then(result => {
        if (!this.stop) {
          this.$store.commit('snackbar/show', {
            color: 'blue',
            text: i18n.t('shipment.consignment.scanned.waiting', { id: 'all' })
          })
        }
        this.returnAfterAccept(bundledStatus, indexes)
      })
      .catch((error: AxiosError) => {
        this.catchError(error)
        if (error.toString().toLowerCase().includes('network error')) {
          this.acceptWithNoInternet = true
        }
      })
      .finally(() => {
        this.sendingStatusesToBackend = false
        localStorage.removeItem('statusData')
      })
  }

  private returnAfterAccept(statuses: BundledStatusesCreateModel, indexes: number[]) {
    this.finishedStop = true
    let tempfinishStop = {
      id: this.stop.id,
      activities: statuses.statusCreateModels.map((status, index) => (status.status.state !== StatusState.NOT_READY) ? this.stop.activities[indexes[index]].id : null).filter(statusIndex => statusIndex !== null)
    }
    this.$emit('accept', tempfinishStop)
    this.$emit('close')

    if (statuses.statusCreateModels.find(data => data.status?.mutations?.items.length)) {
      this.$store.commit("transport/setPlanningUpdated", this.planning.id)
    }
  }

  finishSignOnGlass() {
    if (this.sog >= 0) {
      this.createSOG([this.statusData.data[this.sog]], this.sog)
    } else if (this.sog === -1) {
      this.createSOG(
        this.statusData.data.filter((data: Data) => data.selected),
        this.sog
      )
    }

    this.returnSignOnGlass()
  }

  createSOG(appliesToData: Data[], index: number) {
    const signature = this.signaturePad.saveSignature()
    const document = new StatusDataDocument(
      `signature.png`,
      '',
        DocumentType.SIGNATURE,
      '',
    )
    DocumentTools.fromString(signature.data, document)
    let documentIndex = this.statusData.documents.push(document) - 1

    appliesToData.forEach(data => {
      data.documents.push(documentIndex)
      data.person = this.person || null
    })
  }

  addSignInAbsenceCustomerMessage(event: boolean) {
    this.statusData.data.forEach(data =>
      event
        ? (data.message += data.message ? ', ' + this.$t('sign.signAbsence') : this.$t('sign.signAbsence'))
        : data.message = data.message.replace(this.$t('sign.signAbsence') as string, '')
    )
  }

  returnSignOnGlass() {
    this.sog = -2
    this.person = {}
  }

  get signaturePad(): VueSignaturePad {
    return this.$refs.signaturePad as VueSignaturePad
  }

  onBegin() {
    this.signaturePad.resizeCanvas()
  }

  onEnd() {
    this.signaturePad.resizeCanvas()
  }

  get isNotMobile(): boolean {
    return window.innerWidth > 960
  }

  get isNotMobileOrTablet(): boolean {
    return window.innerWidth > 1440
  }

  get getActions(): string[] {
    return this.actions ? this.actions : []
  }
}
