import {
  DimensionCreateModel,
  DocumentCreateModel,
  DocumentType,
  PersonCreateModel,
  StatusState
} from "@/generated/globalTypes";

export class CreateStatusData {
  data: Data[]
  documents: StatusDataDocument[]
  stopId: number | undefined

  constructor(data: Data[], documents: StatusDataDocument[], stopId: number | undefined) {
    this.data = data
    this.documents = documents
    this.stopId = stopId
  }
}

export class ConsignmentId {
  consignmentIdentifier: string
  shipmentIdentifier: string
  expeditor: number

  constructor(shipmentIdentifier: string, consignmentIdentifier: string, expeditor: number) {
    this.consignmentIdentifier = consignmentIdentifier
    this.shipmentIdentifier = shipmentIdentifier
    this.expeditor = expeditor
  }
}

export class Data {
  message: string
  scannedCargo: string[]
  selected: boolean = true
  deliveredEmballage: Emballage[]
  pickedUpEmballage: Emballage[]
  consignmentIdentifier: ConsignmentId
  planningId: undefined
  documents: number[]
  mutations: Property[]
  clickedZeroEmballage: boolean = false
  person: PersonCreateModel | null
  dimensions: DimensionCreateModel[]
  state: StatusState

  constructor(message: string, scannedCargo: string[], selected: boolean, deliveredEmballage: Emballage[], pickedUpEmballage: Emballage[], consignmentIdentifier: ConsignmentId, planningId: undefined, documents: number[], mutations: Property[], person: PersonCreateModel | null, dimensions: DimensionCreateModel[], statusState: StatusState, clickedZeroEmballage: boolean) {
    this.message = message
    this.scannedCargo = scannedCargo
    this.selected = selected
    this.deliveredEmballage = deliveredEmballage
    this.pickedUpEmballage = pickedUpEmballage
    this.consignmentIdentifier = consignmentIdentifier
    this.planningId = planningId
    this.documents = documents
    this.mutations = mutations
    this.person = person
    this.dimensions = dimensions
    this.state = statusState
    this.clickedZeroEmballage = clickedZeroEmballage
  }
}

export class StatusDataDocument implements DocumentCreateModel {
  base64: string;
  meta: string | null;
  mime: string;
  name: string;
  type: DocumentType;

  constructor(name: string, value: string, type: DocumentType, extension: string) {
    this.name = name
    this.base64 = value
    this.type = type
    this.mime = extension
    this.meta = null
  }
}

export class StatusDataSignature extends StatusDataDocument {
  email: string
  signerFullName: string
}

export class Property {
  key: string
  value: number

  constructor(key: string, value: number) {
    this.key = key
    this.value = value
  }
}

export class Emballage {
  type: string
  amount: number

  constructor(type: string, amount: number) {
    this.type = type
    this.amount = amount
  }
}
